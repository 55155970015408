
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  sup {
    top: -1em;
  }
  sub {
    bottom: 0.15em;
    margin-left: 12px;
  }
  li,
  p,
  div {
    @apply font-normal;
  }
}

@layer components {
  .btn {
    @apply inline-flex items-center p-[10px] md:p-[12px] lg:p-[14px] rounded-md font-semibold hover:bg-black hover:text-white hover:rounded-sm active:rounded-sm active:bg-black focus:outline-0 focus:outline-offset-0 focus:outline-transparent focus:ring focus:ring-0 disabled:opacity-25 transition ease-in-out duration-150;
  }
  .btn-red {
    @apply mt-[8px] bg-darkred capitalize text-white font-semibold py-[10px] md:py-[13px] lg:py-[14px] px-[14px] md:px-[15px] lg:px-[18px] rounded-full hover:rounded-full hover:bg-red;
  }
  .btn-grey {
    @apply mt-[8px] bg-grey400 capitalize text-white font-semibold py-[10px] md:py-[13px] lg:py-[14px] px-[14px] md:px-[15px] lg:px-[18px] rounded-full hover:rounded-full hover:bg-grey400;
  }
  .btn-green {
    @apply mt-[8px] bg-green capitalize text-white font-semibold py-[10px] md:py-[13px] lg:py-[14px] px-[14px] md:px-[15px] lg:px-[18px] rounded-full hover:rounded-full active:bg-darkgreen hover:bg-darkgreen;
  }
  .btn-outline-red {
    @apply mt-[8px] bg-white capitalize text-red border border-red hover:bg-black hover:text-white hover:border-transparent font-normal py-[10px] md:py-[12px] lg:py-[13px] px-[14px] md:px-[15px] lg:px-[18px] rounded-sm;
  }
  .btn-outline-green {
    @apply font-semibold capitalize mt-[8px] bg-white text-green border border-green py-[10px] md:py-[12px] lg:py-[12px] px-[14px] md:px-[15px] lg:px-[18px] rounded-full active:rounded-full active:border-darkgreen active:bg-transparent;
  }
  .btn-outline-grey {
    @apply font-semibold capitalize mt-[8px] bg-white text-grey800 border border-grey800 py-[10px] md:py-[12px] lg:py-[12px] px-[14px] md:px-[15px] lg:px-[18px] rounded-full active:rounded-full active:border-darkgreen active:bg-transparent hover:border-grey800;
  }
  .btn-block {
    @apply w-full justify-center;
  }
  .badge {
    @apply rounded-[14px] px-[12px] text-md font-medium leading-[19px] px-[12px] py-[2px];
  }
  .badge-blue {
    @apply text-white bg-blue;
  }
  .badge-yellow {
    @apply text-black bg-yellow font-medium text-base py-[4px];
  }
  .badge-green {
    @apply text-white bg-green;
  }
  .badge-red {
    @apply text-white bg-red;
  }
  .recommended-badge {
    position: absolute;
    top: -13px;
    right: 25px;
  }
  .price-symbol {
    position: absolute;
    top: -16px;
    right: 25px;
  }
  .row {
    @apply sm:block md:flex lg:flex xl:flex;
  }
  .logoFooter {
    @apply sm:w-full md:w-[500px] lg:w-[500px] xl:w-[632px] sm:m-auto text-center sm:text-center md:text-left lg:text-left xl:text-left !ml-0 sm:mr-[0px] md:mr-[20px] lg:mr-[20px] xl:mr-[20px];
  }
  .social-list {
    @apply flex justify-center sm:justify-center md:justify-start;
  }
  .social-item {
    @apply mr-[15px] xl:mr-[20px] cursor-pointer;
  }
  .footerContainer {
    @apply bg-black text-white p-0 lg:px-24 lg:py-8;
  }
  .footer-content {
    @apply px-[40px] sm:px-[40px] md:px-[60px] lg:px-[100px] xl:px-[120px] py-[35px] sm:py-[40px] md:py-[50px] lg:py-[50px] xl:py-[64px];
  }
  .copyright {
  }
  .link-list {
    @apply w-full sm:w-full mb-[40px] md:w-[150px] lg:w-[140px] xl:w-[150px];
  }
  .copyright {
    @apply text-md text-white opacity-[.74] text-center;
  }
  .fadeOutIn {
    @apply animate-fade-text-out duration-700;
  }
  .fadeInOut {
    @apply animate-fade-text-in duration-700;
  }
  .easeExtend {
    transition: height 0.5s;
  }
  .fade-in-step {
    animation: fadeIn 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
  }
  .fade-out-step {
    animation: fadeOut 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
  }
  .slideup-model {
    position: absolute;
    animation: slideup 0.3s linear;
    transition: 0.5s ease-in-out;
  }
  .slidedown-model {
    position: absolute;
    animation: slidedown 0.3s linear;
    transition: 0.5s ease-in-out;
  }
  label {
    @apply font-medium;
  }
  p {
    @apply font-medium;
  }
  .rtl-input {
    direction: rtl;
  }
  .font-inter {
    font-family: Inter;
  }
  .font-urwarabic {
    font-familly: urwarabic;
  }
  
  @keyframes fadIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadOut {
    100% {
      opacity: 1;
    }
    0% {
      opacity: 0;
    }
  }
  @keyframes slideup {
    100% {
      top: 0%;
    }
    0% {
      top: 100%;
    }
  }
  @keyframes slidedown {
    100% {
      top: 100%;
    }
    0% {
      top: 0%;
    }
  }
}

@layer utilities {
  .filter-none {
    filter: none;
  }
  .filter-grayscale {
    filter: grayscale(100%);
  }
}
[type='text']:focus{
  border-color: white !important;
  --tw-ring-color:white !important;
}
