@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-red-color: #f16d78;
  --primary-green-color: #00a499;
  --primary-green-transparent-color: rgba(0, 164, 153, 0.6);
}
::-moz-selection {
  /* Code for Firefox */
  background: #f16d786b;
  opacity: 0.5;
}

::selection {
  background: #f16d786b;
  opacity: 0.5;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@layer base {
  @font-face {
    font-family: 'Inter';
    src: url("../src/assets/fonts/Inter/Inter-Thin.woff2") format("woff2");
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url("../src/assets/fonts/Inter/Inter-ExtraLight.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url("../src/assets/fonts/Inter/Inter-Light.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url("../src/assets/fonts/Inter/Inter-Regular.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url("../src/assets/fonts/Inter/Inter-Medium.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url("../src/assets/fonts/Inter/Inter-SemiBold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url("../src/assets/fonts/Inter/Inter-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url("../src/assets/fonts/Inter/Inter-ExtraBold.woff2") format("woff2");
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'urwarabic';
    src: url("../src/assets/fonts/arabic/URWGeometricArabic-Thin.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'urwarabic';
    src: url("../src/assets/fonts/arabic/URWGeometricArabic-ExtraLight.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'urwarabic';
    src: url("../src/assets/fonts/arabic/URWGeometricArabic-Light.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'urwarabic';
    src: url("../src/assets/fonts/arabic/URWGeometricArabic-Regular.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'urwarabic';
    src: url("../src/assets/fonts/arabic/URWGeometricArabic-Medium.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'urwarabic';
    src: url("../src/assets/fonts/arabic/URWGeometricArabic-SemiBold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'urwarabic';
    src: url("../src/assets/fonts/arabic/URWGeometricArabic-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }
}
html{
  padding: 0;
  margin: 0;
}
html[lang="en"] *{
    font-family: Inter;
}

html[lang="ar"] *{
  font-family: 'urwarabic' !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

.transitionFiveSec,
.share-content .share li {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.share-content {
  margin: 0 auto;
  position: relative;
  width: 35px;
  height: 35px;
}
.card-frame {
  height: 60px !important;
}
#singleIframe .payment-method-logo-container {
  display: none !important;
}
.share-content .share li {
  display: block;
  list-style: none;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  opacity: 0;
  font-size: 18px;
  text-align: center;
}
.share-content .share li.share-ico {
  z-index: 9;
  opacity: 1;
  box-shadow: unset;
  border: unset;
  padding: 3px;
}

.terms-conditons-content {
  margin: 10px;
}

.terms-conditons-content ul {
  list-style-type: disc;
  margin-left: 20px;
}

/**
 * ==============================================
 * Dot Pulse EN
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #e2e3e4;
  color: #e2e3e4;
  box-shadow: 9999px 0 0 -5px;
  -webkit-animation: dot-pulse 1.5s infinite linear;
  animation: dot-pulse 1.5s infinite linear;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #e2e3e4;
  color: #e2e3e4;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  -webkit-animation: dot-pulse-before 1.5s infinite linear;
  animation: dot-pulse-before 1.5s infinite linear;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  -webkit-animation: dot-pulse-after 1.5s infinite linear;
  animation: dot-pulse-after 1.5s infinite linear;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes dot-pulse-after {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@-webkit-keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@-webkit-keyframes dot-pulse-before {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
@keyframes dot-pulse-before {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

/**
 * ==============================================
 * Dot Pulse AR
 * ==============================================
 */
.dot-pulse-ar {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #e2e3e4;
  color: #e2e3e4;
  box-shadow: 9999px 0 0 -5px;
  -webkit-animation: dot-pulse-ar 1.5s infinite linear;
  animation: dot-pulse-ar 1.5s infinite linear;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.dot-pulse-ar::before,
.dot-pulse-ar::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #e2e3e4;
  color: #e2e3e4;
}
.dot-pulse-ar::before {
  box-shadow: 9984px 0 0 -5px;
  -webkit-animation: dot-pulse-ar-before 1.5s infinite linear;
  animation: dot-pulse-ar-before 1.5s infinite linear;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.dot-pulse-ar::after {
  box-shadow: 10014px 0 0 -5px;
  -webkit-animation: dot-pulse-ar-after 1.5s infinite linear;
  animation: dot-pulse-ar-after 1.5s infinite linear;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes dot-pulse-ar-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

@keyframes dot-pulse-ar-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@-webkit-keyframes dot-pulse-ar {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-ar {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@-webkit-keyframes dot-pulse-ar-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
@keyframes dot-pulse-ar-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-main {
  background: white;
  width: 30%;
  max-width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center; /* Center content horizontally */
  justify-content: center;
}

.modal-main button {
  align-self: flex-end;
  cursor: pointer;
  background: none;
  border: none;
  padding: 50;
}

.display-flex {
  display: flex;
}

.display-none {
  display: none;
}

.frame {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  position: relative;
}

.frame .timer {
  height: 110px;
  position: relative;
  width: 110px;
}

/* .frame .div-2 {
  align-items: center;
  background-color: var(--base-colorswhite);
  border: 2px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(0, 164, 153), rgb(242, 109, 120) 82.29%) 1;
  border-radius: 30px;
  display: inline-flex;
  gap: 8px;
  height: 45px;
  justify-content: center;
  padding: 8px 18px;
  position: relative;
}

.frame .div-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
} */

.frame .icons-instance {
  background-image: url(../src/assets/images/upgrade-green.png) !important;
}

.frame .text-wrapper-2 {
  color: var(--primary-greenbase);
  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  font-style: var(--button-font-style);
  font-weight: var(--button-font-weight);
  letter-spacing: var(--button-letter-spacing);
  line-height: var(--button-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
  border-radius: 3px;
}

/* styles/globals.css */
/* Add these styles for the updated Slider */
/* Add these styles for the updated Slider */
.sliderContainer {
  display: flex;
  overflow: hidden;
  width: 100%; /* Ensure the slider takes full width */
  transition: transform 0.5s ease-in-out; /* Add animation transition */
}

.slide {
  min-width: 200px; /* Set a fixed width for each slide */
  box-sizing: border-box;
  margin-right: 10px; /* Add some margin between slides */
  overflow: hidden;
}

.slideImage {
  width: 100%; /* Make images take full width of the slide */
  height: auto; /* Maintain aspect ratio */
  transition: transform 0.5s ease-in-out; /* Add animation transition */
}

/* styles.css */
.robot-hide {
  z-index: -1;
  right: -15px;
  background-color: rgba(241, 109, 120, 0.2);
}
.robot-show {
  right: -50px;
  animation: 2s showRobot;
  background-color: rgba(241, 109, 120, 0.2);
}
@keyframes showRobot {
  from {
    z-index: -1;
    right: -15px;
  }
  to {
    z-index: 1;
    right: -50px;
  }
}
.modal-feedback {
  top: 0;
  right: 0;
  bottom: 0;
  width: 20%;
  z-index: 1;
  padding: 20px;
  position: fixed;
  max-width: 400px;
  max-height: 1500px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.modal-header-feedback {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleUpgrade {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.descriptionUpgrade {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}
.row-container::-webkit-scrollbar {
  display: none;
}
.nav-bar a {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
.max-h-28{
  max-height: 7rem;
}
.max-h-none{
  max-height: none;
}
/*Table css customization*/
.css-11xur9t-MuiPaper-root-MuiTableContainer-root{
  border: 1px solid #D1D2D4 !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  box-shadow: none !important;
}
th.MuiTableCell-head {
  font-weight: 600;
  font-size: 16px;
}
.MuiTableCell-alignRigt {
  text-align: right;
}

/*Dialog design cutomization*/
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  border-radius: 8px !important;
  padding: 20px;
}

/*Tabs design cutomization*/
.css-1aquho2-MuiTabs-indicator{
  background-color: #00A499;
  height: 3px;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #00A499;
  font-weight: 500;
}
.MuiTabs-flexContainer button{
  text-transform: capitalize;
  font-size: 16px;
}
html{
  height: 100vh;
}

.MuiPaper-rounded{
  border-radius: 16px;
}
#mouse-over-popover .MuiPaper-elevation {
  width: 20%
}
@media (max-width: 600px) {
  .MuiBox-root {
    padding: 0;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
    border-radius: 8px;
    color: #02847B;
    background-color:#00A4990F
  }
}
.shadow-none{
  box-shadow:none;
}
